import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {OrderPage} from './order.page';
import {SharedModuleModule} from '../../shared-module/shared-module.module';
import {OrderPageToolbarComponent} from '../../components/order-page-toolbar/order-page-toolbar.component';

const routes: Routes = [
	{
		path: '',
		component: OrderPage
	}
];

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, RouterModule.forChild(routes), SharedModuleModule],
	declarations: [OrderPage, OrderPageToolbarComponent],
	exports: [OrderPage, OrderPageToolbarComponent]
})
export class OrderPageModule {}
