import {Component, Input, OnInit} from '@angular/core';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';

@Component({
	selector: 'app-preorder-type-chip',
	templateUrl: './preorder-type-chip.component.html',
	styleUrls: ['preorder-type-chip.component.scss']
})
export class PreorderTypeChipComponent implements OnInit {
	@Input()
	preorderType: PreorderType;

	constructor() {}

	ngOnInit() {}
}
