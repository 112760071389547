import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertController} from '@ionic/angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MenuPage} from '../menu/menu.page';
import {AppComponent} from '../../app.component';
import {TranslateService} from '@ngx-translate/core';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';

@Component({
	selector: 'app-order',
	templateUrl: './order.page.html',
	styleUrls: ['order.page.scss']
})
export class OrderPage extends RepositoryDirective implements OnInit {
	static url = 'order';
	static failUrlWithPaymentParam = 'payment/fail/:payment';
	static cancelUrlWithPaymentParam = 'payment/cancel/:payment';
	static paymentFailUrl = 'payment/fail';
	static paymentCancelUrl = 'payment/cancel';
	loading = false;

	constructor(
		protected repository: RepositoryService,
		private translate: TranslateService,
		private snackbarCtrl: MatSnackBar,
		private router: Router
	) {
		super(repository);
	}

	get title(): string {
		if (!OrderUtils.isPreorder(this.order)) {
			return '';
		}
		return this.order.preorder.street + ' ' + this.order.preorder.number + ', ' + this.order.preorder.city;
	}

	static navigate(router: Router) {
		return router.navigateByUrl(AppComponent.largeScreen ? MenuPage.url : OrderPage.url);
	}

	ngOnInit(): void {
		super.ngOnInit();
		console.log('ngOnInit');
		switch (window.location.pathname) {
			case '/' + OrderPage.url:
				break;
			case '/' + OrderPage.paymentCancelUrl:
				console.log(this.translate.instant('order.payment_cancel'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_cancel'), null, {
					duration: 2000
				});
				break;
			case '/' + OrderPage.paymentFailUrl:
				console.log(this.translate.instant('order.payment_fail'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_fail'), null, {
					duration: 5000
				});
				break;
		}
	}

	backClick() {
		MenuPage.navigate(this.router);
	}
}
