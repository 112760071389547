import {Component} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {environment} from 'src/environments/environment';
import Localization from '../../../smoothr-web-app-core/models/Localization';

@Component({
	selector: 'app-short-info-modal',
	templateUrl: './short-info-modal.component.html',
	styleUrls: ['short-info-modal.component.scss']
})
export class ShortInfoModalComponent {
	desc: Localization;
	environment = environment;

	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController, desc: Partial<Localization>) {
		const modal = await modalCtrl.create({
			cssClass: 'short-info-modal auto-height',
			component: ShortInfoModalComponent,
			componentProps: {
				desc
			},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
}
